import React, { useEffect, useState, Fragment } from 'react';
import './styles.css';
import { insuranceProviders, QC_BUYER_INSURANCE, RC_CASETYPE } from './constant';
import { rcCaseTypeService } from '../../../services';
import { withRouter } from 'react-router-dom';
import { openUrlTab, showToastMessages } from '../../../shared/utils/helper';
import { getLocalDatetime, getformattedDate } from '../../../shared/utils/datesHelper';
import UploadButton from '../../../shared/components/upload-button';
import UploadIcon from '../../../shared/utils/images/upload-icon.svg';
import CustomButton from '../../../shared/components/button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SelectDropdown } from '../../../client/components/shared';
import { fileExtension } from '../../constants/constants';

const RcCasetypeQcpanel = ({ match, ...props }) => {
  const [qcInfo, setQcInfo] = useState();
  const [insuranceTypeList,setInsuranceTypeList] = useState([]);
  const {qcBuyerInsurance, fetchQcBuyerInsurance, setQcBuyerInsurance} = props;
  const id = match.params.id;
  useEffect(() => {
    if (props.qcInfo) {
      setQcInfo(props.qcInfo);
    }
  }, [props.qcInfo]);

  useEffect(() => {
    const temp =!!qcBuyerInsurance && !!qcBuyerInsurance.insuranceTypes && qcBuyerInsurance.insuranceTypes.map(item =>{
      return {label: item.type, value: item.id}
    });
    !!temp && setInsuranceTypeList(temp);
  },[qcBuyerInsurance]);

  const refresh = () => {
    const isRefreshed = true;
    rcCaseTypeService
      .refreshQcPanel(id, isRefreshed)
      .then((res) => {
        setQcInfo(res);
        fetchQcBuyerInsurance();
      })
      .catch((err) => {});
  };
        
  const openImage = () => {
    qcInfo['rc_image_url'] && qcInfo['rc_image_url'].length > 0 && qcInfo['rc_image_url'].map((item) => openUrlTab(item));
  };
 
  const openInsuranceImage = () => {
    qcInfo['insurance_image_url'] && qcInfo['insurance_image_url'].length > 0 && qcInfo['insurance_image_url'].map((item) => openUrlTab(item));
  };

  const buyerInsuranceUpload = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    rcCaseTypeService.uploadBuyerInsuranceFile(id, formData)
      .then((resp)=>{
        showToastMessages(resp.message);
        setTimeout(()=>{
          fetchQcBuyerInsurance();
        }, 2000);
      })
      .catch((err)=>{
        showToastMessages(err.message, false);
      });
  };

  const viewFile = (url) => {
      if (!!url) {
        window.open(url);
      } else {
        showToastMessages('No file exists', false);
      }
  };

  const handleBuyerInsuranceSave = () => {
    if (!qcBuyerInsurance[QC_BUYER_INSURANCE.FILE_URL]){
      showToastMessages('Please upload insurance ', false);
      return;
    } else if (!qcBuyerInsurance[QC_BUYER_INSURANCE.EXPIRY_DATE]) {
        showToastMessages('Please select insurance validity date', false);
        return;
    } else if(!qcBuyerInsurance[QC_BUYER_INSURANCE.INSURANCE_PROVIDER]) {
      showToastMessages('Please select insurance provider', false);
      return;
    } 
    // else if(!qcBuyerInsurance.selectedInsuranceId) {
    //     showToastMessages('Please select insurance option', false);
    //     return;
    // }

    rcCaseTypeService.saveBuyerInsurance(id, qcBuyerInsurance[QC_BUYER_INSURANCE.EXPIRY_DATE], qcBuyerInsurance[QC_BUYER_INSURANCE.INSURANCE_PROVIDER], qcBuyerInsurance.selectedInsuranceId
  )
      .then((resp)=>{
        showToastMessages(resp.message);
        setTimeout(()=>{
          fetchQcBuyerInsurance();
        }, 2000);
      })
      .catch((err)=>{
        showToastMessages(err.message, false);
      });
  };

  const deleteFile = (fileId) => {
    rcCaseTypeService.deleteBuyerInsurance(fileId, id)
    .then((resp)=>{
      showToastMessages(resp.message);
      setTimeout(()=>{
        fetchQcBuyerInsurance();
      }, 2000);
    })
    .catch((err)=>{
      showToastMessages(err.message, false);
    });
  };

  const onBuyerInsuranceDataChanged = (date) => {
    const buyer = {...qcBuyerInsurance};
    buyer[QC_BUYER_INSURANCE.EXPIRY_DATE] = getLocalDatetime(date);
    setQcBuyerInsurance(buyer);
  };

  const onChangeInsuranceProvider = (value) => {
    const buyer = {...qcBuyerInsurance, insuranceProvider: value};
    setQcBuyerInsurance(buyer);
  };

  const onChangeInsuranceOptions = (value) =>  {
    const buyer = {...qcBuyerInsurance, selectedInsuranceId: value};
    setQcBuyerInsurance(buyer);
  }

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <button className='btn btn-primary btnRef ml10' onClick={refresh}>
            Refresh
          </button>
          <button className='btn btn-primary btnRef ml10' onClick={openImage}>
            Download Seller RC
          </button>
          <button className='btn btn-primary btnRef ml10' onClick={openInsuranceImage}>
            Download Seller Insurance
          </button>
        </div>
      </div>
      <div className='row'>
        {qcInfo &&
          RC_CASETYPE.map((item, key) => {
            const value =
              item.dataType === 'date'
                ? getformattedDate(qcInfo[item.apiKey])
                : qcInfo[item.apiKey] || '';
            return (
              <Fragment key={key}>
                <div className='col-3 capitalize'>{item.label}</div>
                <div className='col-3'>
                  <input
                    type='text'
                    className='form-control inputSpace'
                    disabled={true}
                    value={value}
                  />
                </div>
              </Fragment>
            );
          })}
      </div>
      <p>Cars24 Insurance</p>
      {!!qcBuyerInsurance && <div className='uploadSectionInsurance'>
        <div className='row mb15'>
          <div className='col-6'>
            <div className='row'>
              <div className='col-6 capitalize'>Cars24 insurance</div>
              <div className='col-6'>
                {!qcBuyerInsurance[QC_BUYER_INSURANCE.FILE_URL] ? <UploadButton
                  label='Upload'
                  onChangeHandler={(file) => buyerInsuranceUpload(file)}
                  accept={`${fileExtension.image},${fileExtension.pdf}`}
                  btnIcon={UploadIcon}
                  iconAlt='Replace Icon'
                  isLoading={props.isLoading}
                /> : 
                  <>
                    <CustomButton
                      label='View'
                      className={'mr20'}
                      iconAlt='Download button Icon'
                      onClick={() => viewFile(qcBuyerInsurance[QC_BUYER_INSURANCE.FILE_URL])}
                    />

                    <CustomButton
                      label='Delete'
                      iconAlt='Download button Icon'
                      onClick={() =>
                        deleteFile(qcBuyerInsurance[QC_BUYER_INSURANCE.FILE_ID])
                      }
                    />
                  </>
                }
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='row'>
              <div className='col-6 capitalize'>Cars24 insurance validity </div>
              <div className='col-6 datepicker_custom'>
                <DatePicker
                  selected={
                    qcBuyerInsurance && qcBuyerInsurance[QC_BUYER_INSURANCE.EXPIRY_DATE]
                      ? new Date(qcBuyerInsurance[QC_BUYER_INSURANCE.EXPIRY_DATE])
                      : null
                  }
                  onChange={(date) => onBuyerInsuranceDataChanged(date)}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                  isClearable={true}
                  dateFormat='dd/MM/yyyy'
                  minDate={qcBuyerInsurance && qcBuyerInsurance[QC_BUYER_INSURANCE.EXPIRY_DATE]
                    ? new Date(qcBuyerInsurance[QC_BUYER_INSURANCE.EXPIRY_DATE])
                    : new Date()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb15">
          <div className='col-6'>
              <div className='row'>
                <div className='col-6 capitalize'>Insurance Provider </div>   
                <div className='col-6'>
                  <SelectDropdown 
                    name='stateId'
                    optionsList={insuranceProviders}
                    value={qcBuyerInsurance && qcBuyerInsurance.insuranceProvider}
                    onChange={(e) => onChangeInsuranceProvider(e.target.value)}/>
                </div>
              </div>  
          </div>
          <div className='col-6'>
            <div className='row'>
              <div className='col-6 capitalize'>Insurance Options </div>   
              <div className='col-6'>
                <SelectDropdown 
                  name='stateId'
                  optionsList={insuranceTypeList}
                  value={qcBuyerInsurance && qcBuyerInsurance.selectedInsuranceId}
                  onChange={(e) => onChangeInsuranceOptions(e.target.value)}/>
              </div>
              </div>
            </div>
        </div>
        <button type='submit' className='btn btn-primary' onClick={handleBuyerInsuranceSave}>Save</button>
      </div>}
    </div>
  );
};

export default withRouter(RcCasetypeQcpanel);
