import React, { useRef } from 'react'
import { withRouter } from 'react-router-dom';
import { fileExtension } from '../../constants/constants';

const DocumentsConfigDataTransfer = ({
  isUploading,
  downloadConfigRulesConnect,
  uploadConfigRulesConnect,
  history,
}) => {
  const $fileInput = useRef();

  const handleDownloadRules = () => {
    downloadConfigRulesConnect();
  }

  const handleUploadRules = () => {
    $fileInput.current.click();
  }

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    uploadConfigRulesConnect({}, formData);
    $fileInput.current.value = "";
  }

  const handleAddDocsRejectionsReasons = () => {
    history.push(`/documents/list`);
  }

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <h5>Regions</h5>
      <div>
        <button className="btn btn-sm btn-success mr-2" onClick={handleAddDocsRejectionsReasons}>Add Documents Rejection Reason</button>
        <button className="btn btn-sm btn-success" onClick={handleDownloadRules}>Download Rules</button>
        <button className="btn btn-sm btn-success ml-2" onClick={handleUploadRules} disabled={isUploading}>
          <input
            type="file"
            className="hide"
            ref={$fileInput}
            onChange={handleFileInputChange}
            accept={fileExtension.excel}
          />
          {isUploading ? "Uploading..." : "Upload Rules"}
        </button>
      </div>
    </div>
  )
}

export default withRouter(DocumentsConfigDataTransfer);