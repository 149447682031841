import React, { useState } from "react";
import { SelectDropdown } from "../../../client/components/shared";
import CustomDatePicker from "../../../shared/components/date-picker/component";
import { FileUploadCta } from "../../../client/components/shared/FileUploadCta";
import { CBox, CCloseButton } from "./c-box";
import Select from 'react-select';
import CTextArea from "../../../shared/components/custom-textarea";
import { useRtoDelayReason } from "../../hooks/custom-hooks/rto-delay-reason-hook";
import useModal from 'react-hooks-use-modal';
import { ViewDelayProof } from "./view-delay-proof";
import { RtoDelayLogs } from "./rto-delay-logs/rto-delay-logs";
import { showToastMessages } from "../../../shared/utils/helper";
import Loader from "../../../shared/components/loader";
import { isCustomDelayReasonSelected, isDateValid } from "./utils";
import { fileExtension } from "../../constants/constants";

export const RtoDelayUpdates = () => {
  const {
    stateOptions,
    rtoOptions,
    rcDelayStagesOptions,
    rtoDelays,
    isVisibleRtoDelayShowMore,
    setRtoOptions,
    getRtosByStateCode,
    getDelayReasonOptionsByStage,
    uploadDelayProof,
    saveRtoDelays,
    updateRtoDelay,
    onShowMore,
    refreshRtoDelayLogs
  } = useRtoDelayReason();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    values: {},
    metaValues: {},
  });
  const [formError, setFormError] = useState('');
  const [Modal, open] = useModal();

  const updateFormValue = (key, value) => {
    setForm({
      ...form,
      values: {
        ...form.values,
        [key]: value,
      }
    })
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "rtoDelayStage") {
      setForm({
        ...form,
        values: {
          ...form.values,
          [name]: value,
          "delayReason": "",
        }
      })
    } else {
      updateFormValue(name, value);
    }
  };

  const handleStateCodeChange = (e) => {
    const { name, value } = e.target;
    getRtosByStateCode(e.target.value);
    setForm({
      ...form,
      values: {
        ...form.values,
        [name]: value,
        "rtoCodes": [],
        "rtoCodesCheckbox": false,
      }
    })
  };

  const handleMultiSelectChange = (selectedValues, name) => {
    if ((selectedValues || []).length === 0) {
      setForm({
        ...form,
        values: {
          ...form.values,
          [name]: selectedValues ? selectedValues : [],
          "rtoCodesCheckbox": false,
        }
      })
    } else {
      updateFormValue(name, selectedValues ? selectedValues : []);
    }
  };

  const handleDateChange = (date, name) => {
    updateFormValue(name, date);
  };

  const refeshRtoLogs = () => {
    refreshRtoDelayLogs();
  }

  const handleFileChange = (file, name) => {
    const formData = new FormData();
    formData.append(name, file);

    setIsLoading(true);

    uploadDelayProof(formData)
    .then(({ data, message }) => {
      updateFormValue(name, data);
      showToastMessages(message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const handleRemoveSelectedRto = (key) => {
    if (!form.values.rtoCodes) return;
    const selectedRtos = [...form.values.rtoCodes].filter(({ value }) => value !== key);
    updateFormValue('rtoCodes', selectedRtos);
  }

  const handleViewDelayProof = async(e) => {
    open();
  };

  const handleDeleteDelayProof = (e) => {
    updateFormValue('uploadProof', null);
  };

  const handleSelectAllRtos = (e) => {
    if (!form.values.stateCode) return;
    const { name } = e.target;
    setForm({
      ...form,
      values: {
        ...form.values,
        [name]: !form.values.rtoCodesCheckbox,
        rtoCodes: form.values.rtoCodesCheckbox ? [] : rtoOptions
      }
    })
  }

  const validate = () => {
    const {
      stateCode,
      rtoCodes,
      rtoDelayStage,
      delayReason,
      customDelayReason,
      delayStartDate,
      delayEndDate
    } = form.values;
  
    if (!stateCode) return 'State is not selected';
    if (!rtoCodes.length) return 'RTO is not selected';
    if (!rtoDelayStage) return 'RTO delay stage is not selected';
    if (!delayReason) return 'Delay reason is not selected';
    if (isCustomDelayReasonSelected(delayReason) && !customDelayReason) return 'Please input the custom message';
    if (!delayStartDate) return 'Please select delay start date';
    if(delayEndDate) {
      if(!isDateValid(delayStartDate, delayEndDate)) return 'Delay end date should be greater than the delay start date';
    }
    return '';
  };

  const handleSave = (e) => {
    e.preventDefault();
    setFormError('');
    const error = validate();
    if (error) {
      setFormError(error);
    } else {
      setIsLoading(true);
      const payload = {
        stateCode: form.values.stateCode,
        rtoDelayDetails: form.values.rtoCodes.map(rtoCode => ({ rtoCode: rtoCode.value })),
        rtoDelayStage: form.values.rtoDelayStage,
        rtoDelayReason: form.values.delayReason,
        delayStartDate:  form.values.delayStartDate,
        delayEndDate: form.values.delayEndDate,
        rtoDelayProof : form.values.uploadProof && form.values.uploadProof.imageName,
        ...(isCustomDelayReasonSelected(form.values.delayReason) && ({ customRtoDelayReason: form.values.customDelayReason }))
      }
      saveRtoDelays(payload)
      .then(({ message }) => {
        showToastMessages(message);
        refeshRtoLogs();
        setForm({
          values: {},
          metaValues: {},
        });
        setRtoOptions([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <>
      <Modal>
        <ViewDelayProof url={form.values.uploadProof ? form.values.uploadProof.imageUrl : null} />
      </Modal>
      <div className="p-4 bg-light">
        {isLoading && (
          <Loader />
        )}
        <div className="h4 mb-3">RTO Delay Updates</div>
        <div className="bg-white py-4 rounded-lg">
          <div className="mb-4 d-flex flex-row col-6">
            <label htmlFor="stateCode" className="col-3">Select State</label>
            <div className="col-6">
              <SelectDropdown
                name='stateCode'
                optionsList={(stateOptions || [])}
                value={form.values.stateCode}
                onChange={handleStateCodeChange}
              />
            </div>
          </div>
          <div className="mb-4 d-flex flex-row col-6">
            <label htmlFor="rtoCodes" className="col-3">Select RTOs</label>
            <div className="col-6">
              <Select
                classNamePrefix="dreMultiselect"
                name="rtoCodes"
                isMulti={true}
                options={(rtoOptions || [])}
                onChange={(selectedValues) => handleMultiSelectChange(selectedValues, "rtoCodes")}
                value={form.values.rtoCodes || []}
                hideSelectedOptions={false}
                styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 10000000 }) }}
                menuPortalTarget={document.body}
                menuPlacement="auto"
              />
            </div>
            <div className="col-12 inline-flex flex-row">
              <input
                type="checkbox"
                value={true}
                name={"rtoCodesCheckbox"}
                onChange={handleSelectAllRtos}
                checked={!!form.values.rtoCodesCheckbox}
              />
              <label htmlFor="rtoCodes" className="col-6">Select All RTOs</label>
            </div>
          </div>
          <div className="mb-4 col-11 ml-3">
            <CBox>
              <div>
                <h2 className="font-weight-bold">RTOs where delays will be updated</h2>
                {form.values.rtoCodes && !!form.values.rtoCodes.length ? (
                  <div className="d-flex flex-wrap overflow-auto">
                    {form.values.rtoCodes.map(({ label, value }, idx) => {
                      return (
                        <CCloseButton
                          key={value}
                          label={label}
                          className={'mr-3 mt-2'}
                          onRemove={() => handleRemoveSelectedRto(value, label)}
                        />
                      )
                    })}
                  </div>
                ) : (
                  <div>No RTO is selected yet</div>
                )}
              </div>
            </CBox>
          </div>
          <div className="mb-4 d-flex flex-row col-6">
            <label htmlFor="rtoDelayStage" className="col-3">RTO delay stage</label>
            <div className="col-6">
              <SelectDropdown
                name='rtoDelayStage'
                optionsList={(rcDelayStagesOptions || [])}
                value={form.values.rtoDelayStage}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-4 d-flex flex-row col-6">
            <label htmlFor="delayReason" className="col-3">Delay reason</label>
            <div className="col-6">
              <SelectDropdown
                name='delayReason'
                optionsList={(getDelayReasonOptionsByStage(form.values.rtoDelayStage) || [])}
                value={form.values.delayReason}
                onChange={handleChange}
              />
            </div>
          </div>
          {isCustomDelayReasonSelected(form.values.delayReason) && (
            <div className="mb-4 col-11 ml-3">
              <CBox>
                <div>
                  <h2 className="font-weight-bold">Custom delay message to customers</h2>
                  <CTextArea
                    name="customDelayReason"
                    value={form.values.customDelayReason}
                    placeholder={"Type here..."}
                    onChange={handleChange}
                  />
                </div>
              </CBox>
            </div>
          )}
          <div className="mb-4 d-flex flex-row col-6">
            <label htmlFor="delayStartDate" className="col-3">Delay start Date</label>
            <div className="col-6">
              <CustomDatePicker
                name='delayStartDate'
                selected={form.values.delayStartDate}
                onDateChange={handleDateChange}
              />
            </div>
          </div>
          <div className="mb-4 d-flex flex-row col-6">
            <label htmlFor="delayEndDate" className="col-3">Delay end Date</label>
            <div className="col-6 d-flex flex-row">
              <CustomDatePicker
                name='delayEndDate'
                selected={form.values.delayEndDate}
                onDateChange={handleDateChange}
              />
              <div className="ml-3 mt-1">(Optional)</div>
            </div>
          </div>
          <div className="mb-4 d-flex flex-row col-6">
            <label htmlFor="uploadProof" className="col-3">Delay proof</label>
            <div className="row-6 ml-3 d-flex flex-row">
              {form.values.uploadProof && form.values.uploadProof.imageUrl ? (
                <div>
                  <button className="btn btn-sm btn-primary" onClick={handleViewDelayProof}>View</button>
                  <button className="btn btn-sm btn-primary ml-3" onClick={handleDeleteDelayProof}>Delete</button>
                </div>
              ) : (
                <FileUploadCta
                  name="uploadProof"
                  label="Upload Proof"
                  accept={`${fileExtension.image},${fileExtension.pdf}`}
                  onChange={handleFileChange}
                />
              )}
              <div className="ml-3">(Optional)</div>
            </div>
          </div>
          <div className="d-flex flex-row col-6">
            <button className="btn btn-sm btn-primary ml-3"  onClick={handleSave}>Save updates</button>
            {formError && <div className="ml-3 text-danger">{formError}</div>}
          </div>
        </div>
        <RtoDelayLogs
          rtoDelays={rtoDelays}
          isVisibleRtoDelayShowMore={isVisibleRtoDelayShowMore}
          stateOptions={stateOptions}
          updateRtoDelay={updateRtoDelay}
          onShowMore={onShowMore}
          refeshRtoDelayLogs={refeshRtoLogs}
        />
      </div>
    </>
  )
}