import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Label, Button } from 'reactstrap';
import styles from "./styles.module.css";
import Loader from "../../../images/preloader.gif";
import {
  getIsLoggedInFromLocalStorage,
  removeDataFromLocalStorage,
  showToastMessages,
  saveToLocalStorage
} from '../../../../shared/utils/helper';
import InputText from '../../../../shared/components/input-text';
import { DispositionConstants, CAMPAIGN_LIST, SEARCH_BY, COUNTRY_LIST, VEHICLE_TYPES } from '../Constants';
import { DataService } from '../../../service';
import { APP_SOURCE, APP_SOURCE_TYPE, ROLES } from '../../../../shared/utils/constants';
import InputRadioButton from '../../../../shared/input-radio-button';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import UploadButton, { UploadIcon } from '../../../../shared/components/upload-button/component';
import { fileExtension } from '../../../../admin-app/constants/constants';

const Welcome = (props) => {
  const isLoggedIn = getIsLoggedInFromLocalStorage();
  const [searchValue, setSearchValue] = useState('');
  const [searchMode, setSearchMode] = useState(SEARCH_BY.PHONE);
  const [isCampaignTypeDisable,setIsCampaignTypeDisable] = useState(false);
  const [campaignType, setCampaignType] = useState('');
  const [buyerPhone, setBuyerPhone] = useState('');
  const [isSearching,setIsSearching] = useState(false);

  useEffect(()=>{
    saveToLocalStorage(APP_SOURCE, APP_SOURCE_TYPE.INDEPENDENT);
    setSearchValue('');
    setSearchMode(SEARCH_BY.PHONE);
    setIsCampaignTypeDisable(false);
    setCampaignType('');
    setBuyerPhone('');
    setIsSearching(false);
  }, []);

  const onChangeSearchType = (value) => {
    setSearchValue('');
    setSearchMode(value);
    if (value === SEARCH_BY.UID){
      setCampaignType(DispositionConstants.CC_MISSED_CALL);
      setIsCampaignTypeDisable(true);
    } else {
      setCampaignType('');
      setIsCampaignTypeDisable(false);
    }
  }

  const onSearchTextChanged = () => {
    setIsSearching(true);
    setIsCampaignTypeDisable(false);
    if (!!searchValue && !!searchMode) {
      if (searchMode === SEARCH_BY.PHONE && searchValue.length < 10) {
        showToastMessages('Invalid phone number', false, 5000);
        setIsSearching(false);
        return;
      }
      DataService.getIndependentCampaign(searchValue, searchMode).then((resp)=>{
        const {campaign='', phone=''} = resp.data || {};
        if (!!campaign && !!phone) {
          setCampaignType(resp.data.campaign);
          setBuyerPhone(resp.data.phone);
          if (campaign === DispositionConstants.INELIGIBLE) {
            showToastMessages("The person is already registered with Cars24. Kindly proceed with a different user", false, 5000);
            setIsCampaignTypeDisable(true);
          }
        } else {
          showToastMessages("No campaign found", false, 5000);
          setBuyerPhone('');
          setIsCampaignTypeDisable(true);
        }
      }).catch((err)=>{
        showToastMessages("No campaign found", false, 5000);
        setBuyerPhone('');
        setIsCampaignTypeDisable(true);
      }).finally(()=>{
        setIsSearching(false);
      });
    } else {
      setIsSearching(false);
    }
  };

  const onSubmitClick=()=> {
    const source = {
      phone: buyerPhone,
      country: COUNTRY_LIST[0].value,
      vehicleType: VEHICLE_TYPES[0].value,
      type: DispositionConstants.CC_OB_INDEPENDENT
    };
    if (campaignType === DispositionConstants.CCOB_ASSISTED_BOOKING) {
      props.history.push({pathname: `/ccob-pre-booking`, state: source})
    } else if(campaignType === DispositionConstants.CC_OUTBOUND_BUYER) {
      props.history.push({pathname: `/ccob-post-booking`, state: source})
    } else if(campaignType === DispositionConstants.CC_MISSED_CALL) {
      props.history.push({pathname: `/ccob-post-delivery`, state: source})
    } else if (campaignType === DispositionConstants.INELIGIBLE) {
      showToastMessages("The person is already registered with Cars24. Kindly proceed with a different user", false, 5000);
    }
  };

  const onFileUpload = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    DataService.uploadLeadFile(formData).then(
      resp =>{
        showToastMessages(resp.message, true, 5000);
      })
      .catch(err => {
        // const {message} = err;
        showToastMessages(err.message, false, 5000);
        // console.log(err);
      })
  }

  if (isLoggedIn) {
    return (
      <div className={styles.welcomeWrapper}>
        <div className="row">
          <div className="col-12 form-group disp-box d-flex align-items-center">
              <Label for="buyerPhone" className="lbl">Search Buyer Using</Label>
            </div>
        </div>
        <div className="row">
          <div className="col-4 form-group disp-box d-flex align-items-center">
            <div className="col-4 form-group disp-box d-flex align-items-center">
              <InputRadioButton
                id={SEARCH_BY.PHONE}
                labelId={SEARCH_BY.PHONE}
                value={SEARCH_BY.PHONE}
                checked={searchMode === SEARCH_BY.PHONE} 
                text={SEARCH_BY.PHONE}
                onClickCallback ={()=> onChangeSearchType(SEARCH_BY.PHONE)}
              />
            </div>
            <div className="col-4 form-group disp-box d-flex align-items-center">
              <InputRadioButton
                id={SEARCH_BY.BOOKING_ID}
                labelId={SEARCH_BY.BOOKING_ID}
                value={SEARCH_BY.BOOKING_ID} 
                checked={searchMode === SEARCH_BY.BOOKING_ID} 
                text={SEARCH_BY.BOOKING_ID} 
                onClickCallback ={()=> onChangeSearchType(SEARCH_BY.BOOKING_ID)}
              />
            </div>
            <div className="col-4 form-group disp-box d-flex align-items-center">
              <InputRadioButton
                id={SEARCH_BY.UID}
                labelId={SEARCH_BY.UID}
                value={SEARCH_BY.UID} 
                checked={searchMode === SEARCH_BY.UID} 
                text={SEARCH_BY.UID} 
                onClickCallback ={()=> onChangeSearchType(SEARCH_BY.UID)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <InputText
              type={searchMode === SEARCH_BY.PHONE ? "number" : ''}
              id="searchValue" 
              name="searchValue"
              value={searchValue}
              maxLength={searchMode === SEARCH_BY.PHONE ? 10 : 30}
              version={"v2"}
              errorMessage={"Required"}
              onChange={(e) => {setSearchValue(e.target.value.toUpperCase());}}
              onBlurCallback={onSearchTextChanged}
              disabled={isSearching}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-2 mt-3">
            <SelectDropdown
              key={'campaignType'}
              name={'campaignType'}
              hideSelectedOptions={false}
              isClearable={false}
              optionsList={CAMPAIGN_LIST}
              onChange={(e) => setCampaignType(e.target.value)}
              value={campaignType}
              isDisabled={isCampaignTypeDisable}
            />
          </div>
          <div className="col-2 mt-3 welcomeSubmitCta">
            <Button className="submit" onClick={onSubmitClick}  disabled={!buyerPhone || !searchValue} >Submit</Button>
          </div>
        </div>
        {window.localStorage.role === ROLES.C2C_LMS_ADMIN && <div className={styles.leadUpload}>
          <div className='row'>
            <div className='col-2 form-group disp-box d-flex align-items-center'>
              <label for='uploadLeadFile' className='lbl'>
                Upload Lead File: 
              </label>
            </div>
            <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
              <UploadButton
                label='Upload Sheet'
                accept={fileExtension.csv}
                btnIcon={UploadIcon}
                onChangeHandler={onFileUpload}
              />
            </div>
          </div>
        </div>}
      </div>
    );
  } else {
    removeDataFromLocalStorage();
    return <Redirect to='/login' />;
  }
};

export default Welcome;
