import React from "react";
import { FileUpload } from "../../../../client/components/shared";
import { ALLOWED_DOC_TYPES, SIZE } from "../../rc-management-detail/constant";
import { fileExtension } from "../../../constants/constants";

export const UploadViewRenderer = ({
  dataItem,
  field,
  onUploadFile,
  onView,
}) => {
  const handleView = () => {
    if (onView) {
      onView(dataItem, field);
    }
  };

  const handleUpload = (docName, file) => {
    onUploadFile(file, dataItem, field);
  }

  return (
    <td className="text-center d-flex flex-row">
      <div className='text-center mt-2' style={{ width: '100px'}}>
        <FileUpload
          id={field}
          name={field}
          data={{}}
          size={SIZE}
          fileType={ALLOWED_DOC_TYPES}
          onChangeHandler={handleUpload}
          accept={`${fileExtension.image},${fileExtension.pdf}`}
        />
      </div>
      {dataItem && dataItem[field] && (
        <button className="btn btn-sm btn-primary mt-1" onClick={handleView}>View</button>
      )}
    </td>
  )
}