import React, { useEffect, useMemo, useState } from "react";
import { CInputLabelField } from "../../../../../shared/components/c-input-label/c-input-label";
import { RC_OTHERS_TRACKER_METADATA } from "./meta-data";
import { SelectDropdown } from "../../../../../client/components/shared";
import { CTextAreaLabelField } from "../../../../../shared/components/c-textarea-label/c-textarea-label";
import DatePicker from "react-datepicker";
import UploadButton, { UploadIcon } from "../../../../../shared/components/upload-button/component";
import CustomButton from "../../../../../shared/components/button";
import { getDelayCategoryList, getDelaySubCategoryList } from "../../constants";
import { onChangeDelayCategory, onChangeDelaySubCategory } from "../../../../viewModels/rc-management";
import { rcManagementService } from "../../../../../services";
import { KEY_AND_FILE_TYPE_MAP, RC_OTHERS_TRACKER_FIELD_KEY_DELAY_KEY_MAP } from "./constants";
import { useRcOthersTrackerForm } from "../../../../hooks/custom-hooks/rc-others-tracker-hook";
import { getLocalDatetime } from "../../../../../shared/utils/datesHelper";
import { fileExtension } from "../../../../constants/constants";

export const RcOthersTrackerForm = ({
  delayReasons,
  originalTrackerData,
  serviceDetail,
  trackerData,
  updateTrackerData,
  fetchTrackerData,
}) => {
  const { trackerConfig } = useRcOthersTrackerForm();
  const fieldsData = useMemo(() => RC_OTHERS_TRACKER_METADATA({
    serviceStatusDropdownOptions: trackerConfig.serviceStatus,
    aadharStatusDropdownOptions: trackerConfig.aadharChangeStatus,
    handoverMethodDropdownOptions: trackerConfig.physicalDocHandoverMethod,
    handoverStatusDropdownOptions: trackerConfig.physicalDocHandoverStatus,
    dispatchLinkDropdownOptions: trackerConfig.dispatchLink,
    serviceDetail,
  }), [serviceDetail, trackerConfig]);

  const [form, setForm] = useState({
    values: {},
    errors: {} 
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const updatedForm = {
      ...trackerData,
      [name]: value,
    }
    updateTrackerData(updatedForm);
  };

  const handleDateChange = (selectedDate, key) => {
    const updatedForm = {
      ...trackerData,
      [key]: getLocalDatetime(selectedDate, true),
    }
    updateTrackerData(updatedForm);
  };

  const handleUploadFile = (file, fieldKey) => {
    const formData = new FormData();
    formData.append('file', file);
    let params = {
      tracker_id: trackerData.id,
      file_type: KEY_AND_FILE_TYPE_MAP[fieldKey],
      rc_application_id: trackerData.rcApplicationId
    };
    rcManagementService["uploadFileRcOthersTracker"](params, formData)
      .then(() => {
        fetchTrackerData();
      });
  };

  const handleViewFile = (id) => {
    rcManagementService["viewFileRcOthersTracker"](id)
      .then((response) => {
        if (response && response.data && response.data.data) {
          window.open(response.data.data.imageUrl);
        }
      });
  };

  const handleDeleteFile = (id, key) => {
    rcManagementService["deleteFileRcOthersTracker"](id)
      .then(() => {
        updateTrackerData({ ...trackerData, [key]: null });
      });
  };

  const renderSingleField = (fieldData, key, colCount) => {
    const { 
      key: fieldKey, 
      label, 
      fieldType,
      isVisible, 
      fieldSubType,
      getDropdownOptions,
      isRequired,
      validateOnChange,
      disabled
    } = fieldData;

    const isFieldVisible = isVisible(form.values);

    if (!isFieldVisible) {
      delete form.values[fieldKey];
      delete form.errors[fieldKey];
      return null;
    };

    const fieldValue = form.values[fieldKey];
    const formValues = form.values;

    let fieldElement = null;

    if (fieldType === "text") {
      fieldElement = (
        <CInputLabelField
          type={fieldSubType || "text"}
          name={fieldKey}
          value={fieldValue}
          onChange={(e) => validateOnChange ? validateOnChange(e, handleChange) : handleChange(e)}
          required={isRequired()}
        />
      )
    } else if (fieldType === "dropdown") {
      fieldElement = (
        <div>
          <SelectDropdown
            name={fieldKey}
            value={fieldValue}
            optionsList={getDropdownOptions(formValues)}
            onChange={handleChange}
            required={isRequired(formValues)}
          />
        </div>
      )
    } else if (fieldType === "textarea") {
      fieldElement = (
        <CTextAreaLabelField
          type={fieldSubType || "text"}
          name={fieldKey}
          value={fieldValue}
          onChange={(e) => validateOnChange ? validateOnChange(e, handleChange) : handleChange(e)}
          required={isRequired()}
        />
      )
    } else if (fieldType === "date") {
      fieldElement = (
        <div className="datepicker_custom">
          <DatePicker
            selected={
              fieldValue
                ? new Date(fieldValue)
                : null
            }
            onChange={(date) => handleDateChange(date, fieldKey, false)}
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
            isClearable={disabled ? false : true}
            disabled={disabled}
            dateFormat='dd/MM/yyyy'
          />
        </div>
      )
    } else if (fieldType === "upload") {
      fieldElement = (
        <>
          {!fieldValue ? (
            <UploadButton
              label='Upload'
              name={fieldKey}
              onChangeHandler={(file) => handleUploadFile(file, fieldKey)}
              accept={fileExtension.image}
              btnIcon={UploadIcon}
              iconAlt='Replace Icon'
            />
          ) : (
            <>
              <CustomButton
                label='View'
                className={'mr20'}
                iconAlt='Download button Icon'
                onClick={() => handleViewFile(fieldValue)}
              />

              <CustomButton
                label='Delete'
                iconAlt='Download button Icon'
                onClick={() =>
                  handleDeleteFile(fieldValue, fieldKey)
                }
              />
            </>
          )}
        </>
      )
    } else if (fieldType === "delay_dropdown") {
      const categories = getDelayCategoryList(delayReasons, RC_OTHERS_TRACKER_FIELD_KEY_DELAY_KEY_MAP[fieldKey]);
      let subCategories = [];
      if (formValues && fieldValue && fieldValue.category) {
        subCategories = getDelaySubCategoryList(delayReasons, RC_OTHERS_TRACKER_FIELD_KEY_DELAY_KEY_MAP[fieldKey], fieldValue.category)
      }
      fieldElement = (
        <>
          <div className="row">
            <div className='col-6'>
              <SelectDropdown
                name={`${fieldKey}_category`}
                optionsList={categories}
                value={fieldValue ? fieldValue.category : null}
                onChange={(value)=> onChangeDelayCategory({ key: fieldKey, data: value, trackerData: formValues, updateTrackerData })}
                isEmptyDefaultValue={originalTrackerData[fieldKey] && originalTrackerData[fieldKey].category ? false : true}
                isSelectRequired={false}
              />
            </div>
            <div className='col-6'>
              <SelectDropdown
                name={`${fieldKey}_subcategory`}
                optionsList={subCategories}
                value={fieldValue ? fieldValue.subCategory: null}
                onChange={(value)=> onChangeDelaySubCategory({key: fieldKey, data: value, trackerData: formValues, updateTrackerData})}
                isEmptyDefaultValue={true}
                isSelectRequired={false}
              />
            </div>
          </div>
        </>
      )
    }

    const fieldError = form.errors[fieldKey];

    const renderElement = (
      <div className="col-6 mt10" key={key}>
        <div className="row">
          <label className='col-6'>{label}</label>
          <div className='col-6'>{fieldElement}</div>
        </div>
        {fieldError && <div className="text-danger">{fieldError}</div>}
      </div>
    );
    
    const shouldStartFromNewRow = fieldData.shouldStartFromNewRow;
    if (shouldStartFromNewRow && colCount%2 === 1) {
      return {
        element: (
          <React.Fragment key={key}>
            <div className="col-6 mt10"></div>
            {renderElement}
          </React.Fragment>
        ),
        updatedColCount: colCount + 2,
      }
    }
    return {
      element: renderElement,
      updatedColCount: colCount + 1,
    };
  };

  useEffect(() => {
    setForm({
      ...form,
      values: trackerData,
    })
  }, [trackerData]);

  console.log("form", form.values);
  let colCount = 0;
  return (
    <form>
      <div className="row">
        {fieldsData.map((fieldData, idx) => {
          const res = renderSingleField(fieldData, idx, colCount);
          if (res) {
            const {
              element,
              updatedColCount
            } = res;
            colCount = updatedColCount;
            return element;
          } else {
            return null;
          }
        })}
      </div>
    </form>
  )
}