import { HOUSE_TYPE, EMPLOYMENT_TYPE, INCOMEPROOF_TYPE } from "../../client/components/shared/Constants";

export const constants = {
  LOGIN_REQUEST_CLIENT: 'c2c',
  LOGIN_REQUEST_TOKEN_TYPE: 'jwt',
  GRID_FILTER_BLANK_STRING: '',
  FORM_ACTION_SAVE: 'Save',
  FORM_ACTION_UPDATE: 'Update'
};

export const carSourceConstants = {
  CUSTOMER_CARE: 'CUSTOMER_CARE',
  B2C: 'B2C',
  GS_CAR: 'GS_CAR',
  MANUAL_UPLOAD: 'MANUAL_UPLOAD',
  C2C_DEALER_CAR: 'C2C_DEALER_CAR',
  GS_DEALER: 'GS_DEALER'
};

export const orderStateConstants = {
  DRAFT: 'DRAFT',
  PUBLISH: 'PUBLISH',
  BOOKED: 'BOOKED',
  SOLD: 'SOLD',
  ARCHIVE: 'ARCHIVE'
};

export const backgroundCheckStatusConstants = {
  PENDING: 'PENDING',
  ASSURED: 'ASSURED',
  NON_ASSURED: 'NON_ASSURED',
  DELIST: 'DELIST'
};

export const dealerTypeConstants = {
  DEALER_TYPE_GS_CAR: 'GS_CAR',
  DEALER_TYPE_C2C_DEALER_CAR: 'C2C_DEALER_CAR'
};

export const agentStatusConstants = {
  PENDING: 'PENDING',
  ASSIGNED_TO_FINANCE: 'ASSIGNED_TO_FINANCE',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  ASSIGNED_TO_VENDOR: 'ASSIGNED_TO_VENDOR'
};

export const legalStatusConstants = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY',
  PARTNERSHIP: 'PARTNERSHIP',
  PROPRIETORSHIP: 'PROPRIETORSHIP',
  HUF: 'HUF',
  OTHERS: 'OTHERS'
};

export const documentConstants = {
  NON_GST_CERTIFICATE: 'NON_GST_CERTIFICATE',
  GST_CERTIFICATE: 'GST_CERTIFICATE',
  PAN_CARD: 'PAN_CARD',
  AADHAR_CARD: 'AADHAR_CARD',
  DECLARATION_FORM: 'DECLARATION_FORM',
  BANK_DOCUMENT: 'BANK_DOCUMENT',
  EMPANELMENT_FORM: 'EMPANELMENT_FORM'
};

export const inventoryStatusConstants = {
  IN_TRANSIT: 'IN_TRANSIT',
  STOCK_IN: 'STOCK_IN',
  STOCK_OUT: 'STOCK_OUT',
  NEW_CAR: 'NEW_CAR',
  FULL_TRANSFER: 'FULL',
  PICKUP_REQUEST: 'PICKUP_REQUEST',
  BID_WON: 'BID_WON'
};

export const nbfcStatus = {
  YES: { key: 'Yes', value: 'Yes' },
  NO: { key: 'No', value: 'No' }
};

export const rcStates = {
  // PENDING: { key: 'PENDING', value: 'Pending' },
  // OK_FOR_DOCS: { key: 'OK_FOR_DOCS', value: 'Ok for docs' },
  // OK_FOR_SALES: { key: 'OK_FOR_SALES', value: 'Ok for Sales' },
  OK_FOR_RTO: { key: 'OK_FOR_RTO', value: 'Ok for RTO' },
  PENDING_DISPUTED: { key: 'DISPUTED', value: 'Disputed' },
  // OK_FOR_SCAN: { key: 'OK_FOR_SCAN', value: 'Ok for Scan' },
  // LEGAL_ISSUE: { key: 'LEGAL_ISSUE', value: 'Legal Issue' },
  OK_FOR_SELF: { key: 'OK_FOR_SELF', value: 'OK for Self' },
  DEAL_CANCELLED: { key: 'DEAL_CANCELLED', value: 'Deal Cancelled' },
  SALES_PENDING: { key: 'SALES_PENDING', value: 'Sales Pending' },
  OPS_PENDING: { key: 'OPS_PENDING', value: 'Ops Pending' },
  PAYMENT_PENDING: { key: 'PAYMENT_PENDING', value: 'Payment Pending' }
};
export const rcType = {
  SELF: { key: 'Self Transfer', value: 'Self Transfer' },
  NOC: { key: 'Only NOC', value: 'Only NOC' },
  FULL_TRANSFER: { key: 'Full Transfer', value: 'Full Transfer' }
};
export const rcTrackerStatus = {

  File_Received: { key: 'File Received', value: 'File Received' },
  E_Receipt_Issued: { key: 'E-Receipt Issued', value: 'E-Receipt Issued' },
  Handed_over_to_the_Agent: {
    key: 'Handed over to the Agent',
    value: 'Handed over to the Agent'
  },
  RC_verification_pending: { key: 'RC Verification Pending', value: 'RC Verification Pending'},
  RC_verification_to_discuss: { key: 'RC Verification To Discuss', value: 'RC Verification To Discuss'},
  RC_verification_rejected: { key: 'RC Verification Rejected', value: 'RC Verification Rejected'},
  RC_transfer_completed: {
    key: 'RC transfer completed',
    value: 'RC transfer completed'
  },
  Handed_over_to_Buyer: {
    key: 'Handed over to Buyer',
    value: 'Handed over to Buyer'
  },
  Physical_RC_received: {
    key: 'Physical RC received',
    value: 'Physical RC received'
  }
};

export const dealStatusTypes = {
  TOKEN_DONE: 'TOKEN_DONE',
  DEAL_LOST: 'DEAL_LOST',
  INITIATED: 'INITIATED',
  PAYMENT: 'PAYMENT'
};

export const dealStatus = {
  [dealStatusTypes.TOKEN_DONE]: {
    key: dealStatusTypes.TOKEN_DONE,
    value: 'Active'
  },
  [dealStatusTypes.DEAL_LOST]: {
    key: dealStatusTypes.DEAL_LOST,
    value: 'Cancelled'
  },
  [dealStatusTypes.INITIATED]: {
    key: dealStatusTypes.INITIATED,
    value: 'Initiated'
  }
};

export const hpTypeValues = {
  HPA: 'HPA',
  HPC: 'HPC',
  HPT: 'HPT'
};

export const hpRequestTypeValues = {
  CREATED: 'CREATED',
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

export const hpRequestTypes = {
  [hpRequestTypeValues.CREATED]: {
    key: hpRequestTypeValues.CREATED,
    value: hpRequestTypeValues.CREATED
  },
  [hpRequestTypeValues.REQUESTED]: {
    key: hpRequestTypeValues.REQUESTED,
    value: hpRequestTypeValues.REQUESTED
  },
  [hpRequestTypeValues.APPROVED]: {
    key: hpRequestTypeValues.APPROVED,
    value: hpRequestTypeValues.APPROVED
  },
  [hpRequestTypeValues.REJECTED]: {
    key: hpRequestTypeValues.REJECTED,
    value: hpRequestTypeValues.REJECTED
  }
};

export const HpTypes = {
  [hpTypeValues.HPA]: {
    key: hpTypeValues.HPA,
    value: hpTypeValues.HPA
  },
  [hpTypeValues.HPC]: {
    key: hpTypeValues.HPC,
    value: hpTypeValues.HPC
  },
  [hpTypeValues.HPT]: {
    key: hpTypeValues.HPT,
    value: hpTypeValues.HPT
  }
};
export const validationStatus = {
  COMPLETED: { key: 'COMPLETED', value: 'Completed' },
  PENDING: { key: 'PENDING', value: 'Pending' }
};
export const paymentStatus = {
  PENDING: { key: 'Pending', value: 'Pending' },
  EXCESS_STATUS: { key: 'Excess', value: 'Excess' },
  COMPLETE: { key: 'Complete', value: 'Complete' }
};
export const stockOutReasonsConstants = {
  SOLD: 'SOLD',
  OUT_FOR_MAINTENANCE: 'OUT_FOR_MAINTENANCE',
  C2C_STORE_MOVEMENT: 'C2C_STORE_MOVEMENT'
};

export const agentStatus = {
  ACTIVE: { key: true, value: 'Active' },
  INACTIVE: { key: false, value: 'In-Active' }
};
export const DEFAULT_PAGE_SIZE = 10;

export const deliveryModeValues = {
  HUB_PICKUP: "HUB_PICKUP",
  HOME_DELIVERY: "HOME_DELIVERY"
}

export const deliveryModes = {
  [deliveryModeValues.HUB_PICKUP]: "Hub Pick-up",
  [deliveryModeValues.HOME_DELIVERY]: "Home Delivery"
};

export const deliveryModesDropdown = [
  { label: deliveryModes.HUB_PICKUP, value: deliveryModeValues.HUB_PICKUP },
  { label: deliveryModes.HOME_DELIVERY, value: deliveryModeValues.HOME_DELIVERY },
];

export const addressProofAvailablilityValues = {
  YES_SAME_AS_AADHAR: "YES_SAME_AS_AADHAR",
  YES_RENT_AGREEMENT: "YES_RENT_AGREEMENT",
  NOT_AVAILABLE: "NOT_AVAILABLE",
}

export const addressProofAvailablility = {
  [addressProofAvailablilityValues.YES_SAME_AS_AADHAR]: "Yes - Same as Aadhar",
  [addressProofAvailablilityValues.YES_RENT_AGREEMENT]: "Yes - Rent Agreement available",
  [addressProofAvailablilityValues.NOT_AVAILABLE]: "Not Available",
};

export const addressProofAvailablilityDropDown = [
  { label: addressProofAvailablility.YES_SAME_AS_AADHAR, value: addressProofAvailablilityValues.YES_SAME_AS_AADHAR },
  { label: addressProofAvailablility.YES_RENT_AGREEMENT, value: addressProofAvailablilityValues.YES_RENT_AGREEMENT },
  { label: addressProofAvailablility.NOT_AVAILABLE, value: addressProofAvailablilityValues.NOT_AVAILABLE },
];

export const Gender = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",
}

export const GenderDropDown = [
  { label: Gender.MALE, value: Gender.MALE },
  { label: Gender.FEMALE, value: Gender.FEMALE },
  { label: Gender.OTHER, value: Gender.OTHER },
];

export const paymentModesValues = {
  CARS24_FINANCE: "CARS24_FINANCE",
  SELF_NAT_BANKING: "SELF_NAT_BANKING",
  SELF_UPI: "SELF_UPI",
  SELF_CASH: "SELF_CASH",
  SELF_CREDIT_DEBIT_CARD: "SELF_CREDIT_DEBIT_CARD",
  SELF_ONLINE_TRANSFER: "SELF_ONLINE_TRANSFER",
  PAY_ON_DELIVERY: "PAY_ON_DELIVERY",
};

export const paymentModes = {
  [paymentModesValues.CARS24_FINANCE]: "Cars24 Finance",
  [paymentModesValues.SELF_NAT_BANKING]: "Self - Net Banking",
  [paymentModesValues.SELF_UPI]: "Self - UPI",
  [paymentModesValues.SELF_CASH]: "Self - Cash",
  [paymentModesValues.SELF_CREDIT_DEBIT_CARD]: "Self - Credit/Debit Card",
  [paymentModesValues.SELF_ONLINE_TRANSFER]: "Self- Online Funds Transfer (NEFT/IMPS/RTGS)",
  [paymentModesValues.PAY_ON_DELIVERY]: "Pay on Delivery",
};


export const paymentModesDropdown = [
  { label: paymentModes.CARS24_FINANCE, value: paymentModesValues.CARS24_FINANCE },
  { label: paymentModes.SELF_NAT_BANKING, value: paymentModesValues.SELF_NAT_BANKING },
  { label: paymentModes.SELF_UPI, value: paymentModesValues.SELF_UPI },
  { label: paymentModes.SELF_CASH, value: paymentModesValues.SELF_CASH },
  { label: paymentModes.SELF_CREDIT_DEBIT_CARD, value: paymentModesValues.SELF_CREDIT_DEBIT_CARD },
  { label: paymentModes.SELF_ONLINE_TRANSFER, value: paymentModesValues.SELF_ONLINE_TRANSFER },
];

export const assistBookingPaymentModesDropdown = [
  { label: paymentModes.CARS24_FINANCE, value: paymentModesValues.CARS24_FINANCE },
  { label: paymentModes.PAY_ON_DELIVERY, value: paymentModesValues.PAY_ON_DELIVERY },
];

export const houseTypeDropdown = [
  { label: HOUSE_TYPE.RENTED, value: HOUSE_TYPE.RENTED },
  { label: HOUSE_TYPE.OWNED, value: HOUSE_TYPE.OWNED },
];

export const employmentTypeDropdown = [
  { label: EMPLOYMENT_TYPE.SALARIED, value: EMPLOYMENT_TYPE.SALARIED },
  { label: EMPLOYMENT_TYPE.SELF_EMPLOYED, value: EMPLOYMENT_TYPE.SELF_EMPLOYED },
];

export const incomeProofStatusDropdown = [
  { label: INCOMEPROOF_TYPE.YES, value: INCOMEPROOF_TYPE.YES },
  { label: INCOMEPROOF_TYPE.NO, value: INCOMEPROOF_TYPE.NO },
];

export const timeSlotsValues = {
  NINE_TO_TWELVE: "9-12",
  TWELVE_TO_THREE: "12-3",
  THREE_TO_SIX: "3-6",
  SIX_TO_NINE: "6-9",
};

export const timeSlots = {
  [timeSlotsValues.NINE_TO_TWELVE]: "9AM to 12 PM",
  [timeSlotsValues.TWELVE_TO_THREE]: "12PM to 3PM",
  [timeSlotsValues.THREE_TO_SIX]: "3PM to 6PM",
  [timeSlotsValues.SIX_TO_NINE]: "6PM to 9PM",
};

export const timeSlotsDrodown = [
  { label: timeSlots[timeSlotsValues.NINE_TO_TWELVE], value: timeSlotsValues.NINE_TO_TWELVE },
  { label: timeSlots[timeSlotsValues.TWELVE_TO_THREE], value: timeSlotsValues.TWELVE_TO_THREE },
  { label: timeSlots[timeSlotsValues.THREE_TO_SIX], value: timeSlotsValues.THREE_TO_SIX },
  { label: timeSlots[timeSlotsValues.SIX_TO_NINE], value: timeSlotsValues.SIX_TO_NINE },
];

export const getPaymentStatusQuestions = (isOtherRcView) => (
  [
    ...(
      !isOtherRcView ? [{
        question: 'Payment marked as received in Transaction Master?',
        keyname: 'payment_received',
        type: 'radio'
      },
      {
        question: 'T+1 Verification calling completed?',
        keyname: 't1_verification_status',
        type: 'radio'
      }
    ] : []
    ),
    { question: 'Disputed?', keyname: 'legal_issue', type: 'radio' },
    ...(
      !isOtherRcView ? [{
        question: 'Verified file on?',
        keyname: [
          { label: 'Call', key: 'verified_on_call' },
          { label: 'Scan', key: 'verified_on_scan' },
          { label: 'Physical', key: 'verified_on_physical' }
        ],
        type: 'checkbox'
      }] : []
    ),
    { question: 'Ops Pending?', keyname: 'ops_pending', type: 'radio' }
  ]
);

export const DOCUMENTS_GRID_ID = {
  REGION: "documentsRegionList",
  CONFIG_LIST: "documentsConfigList",
  CONFIG_ADD: "documentsConfigAdd",
  DOCUMENTS: "documentsList",
  RR_CONFIG_LIST: "documentsRrConfigList",
  RR_CONFIG_ADD: "documentsConfigAdd",
}

export const CASE_TYPES_GRID_ID = {
  CONFIG_LIST: "caseTypesConfigList",
  CONFIG_ADD: "caseTypesConfigAdd"
}

export const DOCKET_MANAGEMENT_GRID_ID = {
  DOCKET_DOCS: "docketDocsList"
}

export const RC_OTHERS_TRACKER_STATUS = [
  {
    "value": "File Received",
    "key": "FILE_RECIEVED"
  },
  {
    "value": "Handed over to Vendor",
    "key": "HANDED_OVER_TO_VENDOR"
  },
  {
    "value": "E-Receipt Issued",
    "key": "E_RECEIPT_ISSUED"
  },
  {
    "value": "Verification Pending",
    "key": "VERIFICATION_PENDING"
  },
  {
    "value": "Verification To Discuss",
    "key": "VERIFICATION_TO_DISCUSS"
  },
  {
    "value": "Verification Rejected",
    "key": "VERIFICATION_REJECTED"
  },
  {
    "value": "Service Completed",
    "key": "SERVICE_COMPLETED"
  },
  {
    "value": "Handed over to Customer",
    "key": "HANDED_OVER_TO_CUSTOMER"
  }
]

export const fileExtension = {
  image: ".jpg,.jpeg,.png,.webp",
  pdf: ".pdf",
  csv: ".csv",
  excel: ".xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}