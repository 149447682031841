import React from 'react';
import { withRouter } from 'react-router-dom';
import { FileUpload } from '../../../client/components/shared';
import { rcManagementService } from '../../../services';
import { SIZE, ALLOWED_DOC_TYPES, fileType } from './constant';

import InputRadioButton from '../../../shared/input-radio-button';
import './style.css';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import { YES, NO, NA, NA_TEXT } from '../../../shared/utils/constants';
import { useIsRcOthers } from '../../../shared/hooks';
import ShowInDocketToggle from './showInDocketToggle';
import { fileExtension } from '../../constants/constants';

const RcDocument = ({
  documents,
  dealId,
  type,
  deleteFile,
  updateDocument,
  handleOptionChange,
  labels,
  location,
  match
}) => {
  const isOtherRcView = useIsRcOthers();
  const onUpload = (docName, file, fileFor, index) => {
    if(isOtherRcView) {
      const formData = new FormData();
      formData.append('file', file);
      rcManagementService
        .uploadOtherRcDocument({id: match.params.id, fileType: fileFor, fileUserType: type, fileObject: formData})
        .then((res) => {
          updateDocument(index, 'file_name', res);
          updateDocument(index, 'id', res);
        })
        .catch((err) => {});
    } else {
      const formData = new FormData();
      formData.append('file', file);
      const params = {
        fileUserType: type,
        dealId,
        fileType: fileFor,
      }
      rcManagementService
        .uploadFile(
          params,
          formData,
        )
        .then((res) => {
          updateDocument(index, 'file_name', res);
          updateDocument(index, 'id', res);
        })
        .catch((err) => {});
    }
  };
  const showView = ({ id: fileid, file_type, file_url }) => {
    if(file_type === fileType.chassisImprint) {
      window.open(file_url, "_blank");
      return;
    }

    if (fileid) {
      if(isOtherRcView) {
        rcManagementService
        .getOthersRcDocumentViewFile(fileid)
        .then((res) => {
          window.open(res.imageUrl);
        })
        .catch((err) => {});
      } else {
        rcManagementService
          .getRcDocumentViewFile(fileid)
          .then((res) => {
            window.open(res.imageUrl);
          })
          .catch((err) => {});
      }
    }
  };
  const isChecked = (status, value) => {
    return status === value;
  };

  const disableRadioInputs = (documentType) => documentType.file_user_type === 'Buyer' && [fileType.rcConsentForm, fileType.rcFacilityForm, fileType.chassisImprint].includes(documentType.file_type);
  const disableFileUpload = (documentType) => {
    const uploadDisabledFileTypes = [fileType.chassisImprint];
    return uploadDisabledFileTypes.includes(documentType.file_type) || (documentType.file_status === YES && !documentType.file_name ? false : true);
  }
  const showDocViewButton = (documentType) => {
    if(documentType.file_type === fileType.chassisImprint && documentType.file_url) {
      return true;
    }
    return documentType && documentType.file_name;
  }
  
  return (
    <React.Fragment>
      <Accordion>
        {({ onClick, currentVisibleStates }) => (
          <Accordion.Content>
            <div className='borderWrapper'>
              <Accordion.Heading>
                <h2
                  className='font-weight-bold rcDocuments p0'
                  onClick={() => onClick({ index: 0 })}
                >
                  {type} Document
                  <ArrowIcon
                    grey={'GreyArrow'}
                    rotateBy={currentVisibleStates[0] ? 180 : 90}
                  />
                </h2>
              </Accordion.Heading>
              <Accordion.Body>
                <table
                  className={
                    currentVisibleStates[0]
                      ? 'accordionShow table mt20 p0'
                      : 'accordionHide'
                  }
                >
                  <thead>
                    <tr>
                      <th width='350px' scope='col'>
                        Document Type
                      </th>
                      <th width='750px' colSpan='4' scope='col'>
                        Available
                      </th>
                      {type === "Buyer" && !isOtherRcView && (
                        <th width='350px' scope='col'>
                          Show in Docket
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {documents &&
                      documents.map((documentType, index) => {
                        return (
                          <>
                            {Object.keys(labels).map((item, labelIndex) => {
                              return (
                                <React.Fragment key={labelIndex}>
                                  {documentType.file_user_type === type &&
                                  item === documentType.file_type ? (
                                    <tr>
                                      <td>{labels[item]} </td>
                                      <td className='d-flex'>
                                        <div
                                          className={
                                            'inputRadioWrap col-6 buyDocumentRadio rcDocumentradioBox'
                                          }
                                        >
                                          <div className='row'>
                                            <div className='col-4 text-center radioHandle'>
                                              <div className='row'>
                                                <InputRadioButton
                                                  labelId={YES + index}
                                                  id={YES + index}
                                                  value={YES}
                                                  checked={isChecked(
                                                    documentType.file_status,
                                                    YES
                                                  )}
                                                  name={
                                                    documentType.file_user_type +
                                                    index
                                                  }
                                                  onClickCallback={(e) =>
                                                    handleOptionChange(index, e)
                                                  }
                                                  isDisabled = {disableRadioInputs(documentType)}
                                                  text={YES}
                                                />
                                              </div>
                                            </div>
                                            <div className='col-4 text-center radioHandle'>
                                              <InputRadioButton
                                                labelId={NO + index}
                                                id={NO + index}
                                                value={NO}
                                                checked={isChecked(
                                                  documentType.file_status,
                                                  NO
                                                )}
                                                name={
                                                  documentType.file_user_type +
                                                  index
                                                }
                                                onClickCallback={(e) => {
                                                  documentType.file_name &&
                                                    deleteFile(
                                                      documentType.id,
                                                      index
                                                    );
                                                  handleOptionChange(index, e);
                                                }}
                                                isDisabled = {disableRadioInputs(documentType)}
                                                text={NO}
                                              />
                                            </div>
                                            <div className='col-4 text-center radioHandle'>
                                              <InputRadioButton
                                                labelId={NA + index}
                                                id={NA + index}
                                                value={NA}
                                                checked={isChecked(
                                                  documentType.file_status,
                                                  NA
                                                )}
                                                name={
                                                  documentType.file_user_type +
                                                  index
                                                }
                                                onClickCallback={(e) => {
                                                  documentType.file_name &&
                                                    deleteFile(
                                                      documentType.id,
                                                      index
                                                    );
                                                  handleOptionChange(index, e);
                                                }}
                                                //checked={documentType.file_status === {NA}}
                                                isDisabled = {disableRadioInputs(documentType)}
                                                text={NA_TEXT}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {showDocViewButton(documentType) && (
                                            <div className='col-2 text-center'>
                                              <button
                                                className='btn btn-primary'
                                                onClick={() =>
                                                  showView(documentType)
                                                }
                                              >
                                                View
                                              </button>
                                            </div>
                                        )}
                                        {documentType && documentType.file_name && (
                                            <div className='col-2 text-center'>
                                              <button
                                                className='btn btn-primary'
                                                onClick={() =>
                                                  deleteFile(
                                                    documentType.id,
                                                    index,
                                                    documentType.file_name
                                                  )
                                                }
                                              >
                                                Delete
                                              </button>
                                            </div>
                                        )}
                                        <div className='col-2 text-center'>
                                          <FileUpload
                                            indexof={index}
                                            onChangeHandler={onUpload}
                                            data={documentType}
                                            docName={documentType.file_name}
                                            fileFor={documentType.file_type}
                                            fileType={ALLOWED_DOC_TYPES}
                                            size={SIZE}
                                            accept={`${fileExtension.image},${fileExtension.pdf}`}
                                            isDisabled={disableFileUpload(documentType)}
                                          />
                                        </div>
                                      </td>
                                      {type === "Buyer" && !isOtherRcView && (
                                        <td>
                                          <ShowInDocketToggle
                                            dealId={dealId}
                                            docData={documentType}
                                          />
                                        </td>
                                      )}
                                    </tr>
                                  ) : null}
                                </React.Fragment>
                              );
                            })}
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </Accordion.Body>
            </div>
          </Accordion.Content>
        )}
      </Accordion>
    </React.Fragment>
  );
};
export default withRouter(RcDocument);
